import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';

import { Layout, SEO, Hero, ContactSection } from '../components';
import ProjectCarousel from '../components/ProjectCarousel';
import { useGraphQL } from '../hooks';

function ServicesPage() {
  const projects = useStaticQuery(graphql`
    {
      project1: file(name: { eq: "project-1" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      project2: file(name: { eq: "project-2" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      project3: file(name: { eq: "project-3" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      project4: file(name: { eq: "project-4" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      project5: file(name: { eq: "project-5" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      project6: file(name: { eq: "project-6" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const projArray = [
    {
      img: projects.project1,
      description:
        'Power & NBN for multiple subdivision from Mudgee to Broken Hill up to Tweed Heads & south to Forster.',
    },
    {
      img: projects.project2,
      description: 'Installation of new overhead power pole in Guyra NSW.',
    },
    {
      img: projects.project3,
      description:
        'Installation of new Streetlights at the Moore Creek Road roundabout.',
    },
    {
      img: projects.project4,
      description:
        'Installation of 4 way ground service column see-saw baseplate streetlight Cnr of Peel St & Marius St Tamworth NSW.',
    },
    {
      img: projects.project5,
      description:
        'Installation of new street lighting for Hastings River Drive Port Macquarie NSW.',
    },
    {
      img: projects.project6,
      description: 'Installation of new under ground cable.',
    },
  ];
  return (
    <Layout>
      <SEO title="Services" />
      <HeroSection />
      <Services />
      <ProjectCarousel allFile={projArray} bgGrey />
      <ContactSection bgColorClass="bg-white" />
    </Layout>
  );
}

function HeroSection() {
  const { servicesHero } = useGraphQL();

  return (
    <Hero image={servicesHero.childImageSharp.fluid}>
      <div className="w-full space-y-4 text-center">
        <h1 className="text-white heading-1">Services</h1>
        <Link to="/contact/" className="text-white button">
          Get in touch
        </Link>
      </div>
    </Hero>
  );
}

function Services() {
  const { serviceSection } = useGraphQL();
  return (
    <article className="relative bg-white">
      <div className="w-full px-4 py-8 mx-auto md:py-16 max-w-7xl sm:px-6 lg:px-8">
        <div className="grid justify-center gap-8 sm:gap-14 md:grid-cols-2">
          <div className="w-full mx-auto max-w-prose">
            <div className="relative h-0 aspect-ratio-square">
              <div className="absolute inset-0 flex bg-sky-blue">
                <GatsbyImage
                  fluid={serviceSection.childImageSharp.fluid}
                  className="flex-1"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center md:py-12">
            <div>
              <p>
                We can take care of the entire process from the very start to
                completion including liaising with authorities on your behalf.
              </p>
              <h2 className="mt-8 text-2xl font-bold tracking-wider text-center uppercase text-light-blue md:text-left">
                Services:
              </h2>
              <div className="mt-2 prose">
                <ul>
                  <li>Installation of major power lines.</li>
                  <li>
                    All Level 1 electrical works from street lighting projects
                    for councils to supply a transformer for a farmer.
                  </li>
                  <li>Provide level 3 electrical designs.</li>
                  <li>Level 2 electrical connection.</li>
                  <li>Design &amp; construct NBN pit &amp; pipe.</li>
                  <li>Excavation works.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default ServicesPage;
